exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cloud-consulting-js": () => import("./../../../src/pages/cloud-consulting.js" /* webpackChunkName: "component---src-pages-cloud-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-devops-consulting-js": () => import("./../../../src/pages/devops-consulting.js" /* webpackChunkName: "component---src-pages-devops-consulting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-apply-js": () => import("./../../../src/pages/job-apply.js" /* webpackChunkName: "component---src-pages-job-apply-js" */),
  "component---src-pages-managed-squads-js": () => import("./../../../src/pages/managed-squads.js" /* webpackChunkName: "component---src-pages-managed-squads-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-professional-outsourcing-js": () => import("./../../../src/pages/professional-outsourcing.js" /* webpackChunkName: "component---src-pages-professional-outsourcing-js" */),
  "component---src-pages-software-projects-js": () => import("./../../../src/pages/software-projects.js" /* webpackChunkName: "component---src-pages-software-projects-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-cases-list-js": () => import("./../../../src/templates/cases-list.js" /* webpackChunkName: "component---src-templates-cases-list-js" */),
  "component---src-templates-cases-post-js": () => import("./../../../src/templates/cases-post.js" /* webpackChunkName: "component---src-templates-cases-post-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-jobs-list-js": () => import("./../../../src/templates/jobs-list.js" /* webpackChunkName: "component---src-templates-jobs-list-js" */),
  "component---src-templates-jobs-post-js": () => import("./../../../src/templates/jobs-post.js" /* webpackChunkName: "component---src-templates-jobs-post-js" */),
  "component---src-templates-posts-list-js": () => import("./../../../src/templates/posts-list.js" /* webpackChunkName: "component---src-templates-posts-list-js" */),
  "component---src-templates-posts-post-js": () => import("./../../../src/templates/posts-post.js" /* webpackChunkName: "component---src-templates-posts-post-js" */)
}

